import logo from './pscalar_logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Contact: <a href="mailto:m@pscalar.xyz" className="App-link"><code>m@pscalar.xyz</code></a>
        </p>
      </header>
    </div>
  );
}

export default App;
